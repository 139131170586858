<template>
  <div class="delivery-detail-card border rounded">
    <div class="reservation-detail-card-header d-flex p-4">
      <div v-if="externalId" class="id-text pl-2 w-50">
        <p class="font-weight-bold text-sm mb-2">External ID</p>
        <p class="text-xl">{{ externalId }}</p>
      </div>
      <div class="id-text mt-4 mt-md-0 w-50">
        <p class="font-weight-bold text-sm mb-2">ID Orden</p>
        <p class="text-xl">{{ shortID }}</p>
      </div>
    </div>
    <div class="delivery-detail-card-table p-4 text-sm">
      <div v-if="isParcel" class="detail-row border-bottom">
        <p class="font-weight-bold">No. Guía</p>
        <p class="text-capitalize-first">{{ delivery.id }}</p>
      </div>
      <div v-if="isParcel" class="border-bottom detail-row">
        <p class="font-weight-bold">Entrega estimada</p>
        <p class="text-capitalize-first">{{ deliveryDate }}</p>
      </div>
      <div class="border-bottom detail-row">
        <p class="font-weight-bold">Fecha de solicitud</p>
        <p class="text-capitalize-first">{{ createdDate }}</p>
      </div>
      <div v-if="pickUpSchedule" class="border-bottom detail-row">
        <p class="font-weight-bold">Horario de recolección</p>
        <p class="text-capitalize-first">{{ pickUpSchedule }}</p>
      </div>
      <div v-if="deliverySchedule" class="border-bottom detail-row">
        <p class="font-weight-bold">Horario de entrega</p>
        <p class="text-capitalize-first">{{ deliverySchedule }}</p>
      </div>
      <div
        v-if="delivery.additionalHoursRequest"
        class="border-bottom detail-row "
      >
        <p class="font-weight-bold">Tiempo extra</p>
        <p>{{ `${delivery.additionalHoursRequest} hrs` }}</p>
      </div>
      <div v-if="driverName" class="border-bottom detail-row">
        <p class="font-weight-bold">Mensajero asignado</p>
        <p>{{ driverName }}</p>
      </div>
      <div v-if="vehicleType != null" class="border-bottom detail-row">
        <p class="font-weight-bold mb-0">Vehículo</p>
        <div class="d-flex align-items-center">
          <p>{{ vehicleName }}</p>
          <z-vehicle :type="vehicleType" class="ml-3 icon-vehicle" />
        </div>
      </div>
      <address-card
        v-if="delivery.origin"
        :address="delivery.origin"
        address-type="origin"
      />
      <address-card
        v-if="delivery.destination"
        :address="delivery.destination"
        address-type="destination"
      />
    </div>
  </div>
</template>

<script>
import AddressCard from "@zubut/common/src/components/AddressCard";
import { formatISO } from "@zubut/common/src/utils/time";
import { makeShortUUID } from "@zubut/common/src/utils/strings";
import VehicleType from "@zubut/common/src/constants/vehicles/type";
import ZVehicle from "@zubut/common/src/components/ZVehicle";
import ServiceType from "@zubut/common/src/constants/services/type";
import ParcelsDelivery from "@zubut/common/src/constants/parcels/schedule-delivery";
import ParcelsPickUp from "@zubut/common/src/constants/parcels/schedule-pick-up";

export default {
  name: "DeliveryDetailCard",

  components: {
    AddressCard,
    ZVehicle
  },

  props: {
    delivery: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      default: ServiceType.NUM_MULTI_POINT
    }
  },

  computed: {
    createdDate() {
      return this.delivery.createdAt
        ? formatISO(this.delivery.createdAt, "iiii d 'de' LLLL, Y H':'mm 'hrs'")
        : "";
    },

    pickUpSchedule() {
      return this.delivery.pickUpSchedule != null
        ? ParcelsPickUp.get[this.delivery.pickUpSchedule]
        : "";
    },

    deliverySchedule() {
      return this.delivery.deliverySchedule != null
        ? ParcelsDelivery.get[this.delivery.deliverySchedule]
        : "";
    },

    deliveryDate() {
      return this.delivery.deliveryDate
        ? formatISO(
            this.delivery.deliveryDate,
            "iiii d 'de' LLLL, Y H':'mm 'hrs'"
          )
        : "";
    },

    externalId() {
      return this.delivery.externalId
        ? makeShortUUID(this.delivery.externalId)
        : "";
    },

    shortID() {
      return makeShortUUID(this.delivery.id);
    },

    acceptedAt() {
      return this.delivery.resume && this.delivery.resume.acceptedAt
        ? this.delivery.resume.acceptedAt
        : null;
    },

    finishedAt() {
      return this.delivery.resume && this.delivery.resume.finishedAt
        ? this.delivery.resume.finishedAt
        : null;
    },

    driverName() {
      return this.delivery.driver;
    },

    vehicleType() {
      return this.delivery.vehicleType;
    },

    vehicleName() {
      return VehicleType.get[this.vehicleType];
    },

    isParcel() {
      return ServiceType.isParcel(this.type);
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery-detail-card {
  border-radius: 20px;
  overflow: hidden;

  .detail-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;

    p {
      margin: 0;
    }

    .icon-vehicle {
      width: 1.5rem;
      height: 1.5rem;
      flex-wrap: wrap;
      display: flex;
      margin-bottom: 6px;
    }
  }
  .reservation-detail-card-header {
    background-color: $solitude;
  }
}
</style>
