<template>
  <div class="mt-3">
    <z-dropdown
      id="dropdown-logs"
      v-model="filterValue"
      search
      :options="options"
      name="Tipo de registro"
    />

    <z-table
      show-empty
      hover
      responsive
      class="z-table mt-3"
      :fields="fields"
      :items="items"
      :busy="loading"
    >
      <template v-slot:empty>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay eventos
        </p>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template v-slot:cell(createdAt)="row">
        <div class="width-content">
          <div>
            {{ row.item.createdAt }}
          </div>
          {{ row.item.eventName }}
        </div>
      </template>

      <!-- Start: Cells -->
      <template v-slot:cell(actions)="{ item }">
        <div v-if="item.signature" class="d-flex">
          <div
            v-tooltip.top="'Descargar evidencia'"
            role="button"
            @click="downloadSignature(item.signature)"
          >
            <font-awesome-icon icon="sign-file" class="sign-icon" size="lg" />
          </div>
        </div>
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="totalRows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import ZTable from "@zubut/common/src/components/ZTable";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import { imageDownload } from "@zubut/common/src/utils/download";
import { makeShortUUID } from "@zubut/common/src/utils/strings";
import { formatISO } from "@zubut/common/src/utils/time";
import MapMixin from "@zubut/common/src/mixins/map";
import pagination from "@zubut/common/src/mixins/pagination";
import Logs from "@zubut/common/src/constants/parcels/logs";
import Parcels from "@/services/parcels";

export default {
  name: "DeliveryDetailParcelLogs",

  components: {
    ZDropdown,
    ZTable,
    ZTablePagination
  },

  mixins: [MapMixin, pagination],

  props: {
    parcelId: {
      type: String,
      required: true,
      default: ""
    }
  },

  data() {
    return {
      logs: {},
      fields: [
        {
          key: "createdAt",
          label: "Fecha"
        },
        {
          key: "idshort",
          label: "ID"
        },
        { key: "driver.name", label: "Mensajero" },
        { key: "actions", label: "" }
      ],
      items: [],
      logsDestination: [{ lat: 0, lng: 0 }],
      loading: true,
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      filterValue: null,
      options: [{ text: "Todos", value: null }].concat(Logs.options)
    };
  },

  watch: {
    logs: {
      handler(newVal) {
        if (newVal) {
          this.mountedData();
        }
      }
    },

    filterValue: {
      handler() {
        this.getLogs();
      }
    },
    currentPage: {
      handler() {
        this.getLogs();
      }
    },
    perPage: {
      handler() {
        this.getLogs();
      }
    }
  },

  created() {
    this.getLogs();
  },

  methods: {
    getLogs() {
      this.items = [];
      const { filter, where } = this.buildRequest();
      this.loading = true;
      Parcels.logs(this.parcelId, { filter, where })
        .then(res => {
          if (res.meta.skip === 0) {
            this.totalRows = res.meta.count;
          }
          this.logs = res.data;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .then(() => {
          this.loading = false;
        });
    },

    mountedData() {
      this.items = [];
      this.logsDestination = [{ lat: 0, lng: 0 }];
      if (this.logs.length) {
        this.logs.forEach((log, index) => {
          const idshort = makeShortUUID(log.id);
          this.items.push({
            index: index,
            id: log.id,
            idshort: idshort,
            createdAt: formatISO(log.createdAt, "d 'de' MMM,  u  h:mm a"),
            event: log.event,
            eventName: Logs.get[log.event],
            driver: log.driver,
            signature: log.signature,
            _rowVariant: null
          });
          if (log.position) {
            if (this.logsDestination[0].lat == 0) {
              this.logsDestination[0] = log.position;
            }
            this.logsDestination.push(log.position);
          }
        });
      }
    },

    buildRequest() {
      const filter = {
        ...this.filter,
        order: "createdAt DESC"
      };
      if (this.filterValue != null) {
        const where = { event: this.filterValue };
        return { filter, where };
      } else {
        return { filter };
      }
    },

    downloadSignature(signature) {
      imageDownload(signature, `parcel-signature.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
.width-content {
  width: max-content;
}

.sign-icon:hover {
  color: var(--primary);
  cursor: pointer;
}
</style>
