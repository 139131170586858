<template>
  <div class="z-delivery-normal-logs py-3">
    <loading-spinner v-if="loading" />
    <template v-else>
      <div
        v-if="isScheduledService"
        class="scheduled-delivery-register border-bottom"
      >
        <div class="text-left">
          <div>
            Se espera la entrega
            <b>{{ scheduledServiceDate }}</b> a las
            <b>{{ scheduledServiceTime }}</b> horas.
          </div>
        </div>
      </div>
      <b-list-group flush>
        <template>
          <b-list-group-item
            v-for="(log, index) in sortedLogs"
            :key="`parcel-log-${index}`"
          >
            <div class="d-flex justify-content-between w-100">
              <div>
                {{ log.eventName }}
              </div>
              <div>
                {{ log.date }}
              </div>
            </div>
          </b-list-group-item>
        </template>
        <template>
          <b-list-group-item v-if="isCanceledByClientService">
            Cancelaste exitosamente tu entrega
            <div>
              {{ finishedTime }}
            </div>
          </b-list-group-item>
          <b-list-group-item v-if="isCanceledByZubutService">
            Tu entrega fue cancelada por Zubut
            <div>
              {{ finishedTime }}
            </div>
          </b-list-group-item>
          <b-list-group-item v-if="isCanceledByDriverService">
            Tu entrega fue cancelado por el mensajero
            <div>
              {{ finishedTime }}
            </div>
          </b-list-group-item>
        </template>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import ServiceStatus from "@zubut/common/src/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import Logs from "@zubut/common/src/constants/parcels/logs";
import { format, formatISO } from "@zubut/common/src/utils/time";
import Deliveries from "@/services/deliveries";
import { fromUnixTime, isToday, isTomorrow, parseISO } from "date-fns";
import { incidenceMessage } from "@zubut/common/src/utils/incidences";

export default {
  name: "DeliveryDetailLogs",

  props: {
    delivery: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      default: ServiceType.NUM_MULTI_POINT
    }
  },

  data() {
    return {
      loading: false,
      logs: [],
      incidences: [],
      arrivedAt: null,
      deliveredAt: null,
      exitAt: null
    };
  },

  computed: {
    arrivedTime() {
      if (this.arrivedAt != null) {
        return formatISO(this.arrivedAt, "d 'de' MMM. 'de' yyyy h:mm a");
      }
      return "";
    },

    deliveredTime() {
      if (this.deliveredAt != null) {
        return formatISO(this.deliveredAt, "d 'de' MMM. 'de' yyyy h:mm a");
      }
      return "";
    },

    additionalOptions() {
      if (this.delivery && this.delivery.additionalOptions) {
        return this.delivery.additionalOptions;
      }
      return {};
    },

    creationTime() {
      return formatISO(this.delivery.createdAt, "d 'de' MMM. 'de' yyyy h:mm a");
    },

    finishedAt() {
      let date = "";
      if (this.delivery.cancelledAt) {
        date = this.delivery.cancelledAt;
      } else if (this.delivery.resume && this.delivery.resume.finishedAt) {
        date = this.delivery.resume.finishedAt;
      }
      return date;
    },

    finishedTime() {
      return this.finishedAt
        ? formatISO(this.finishedAt, "d 'de' MMM. 'de' yyyy h:mm a")
        : "";
    },

    exitTime() {
      return this.exitAt
        ? formatISO(this.exitAt, "d 'de' MMM. 'de' yyyy h:mm a")
        : "";
    },

    isScheduledService() {
      return (
        this.delivery.status === ServiceStatus.NUM_SCHEDULED &&
        this.additionalOptions.scheduled
      );
    },

    isSuccessfulService() {
      return (
        this.finishedTime && this.delivery.status === ServiceStatus.NUM_FINISHED
      );
    },

    isCanceledByClientService() {
      return (
        this.finishedTime &&
        this.delivery.status === ServiceStatus.NUM_CLIENT_CANCELLED
      );
    },

    isCanceledByZubutService() {
      return (
        this.finishedTime &&
        this.delivery.status === ServiceStatus.NUM_ZUBUT_CANCELLED
      );
    },

    isCanceledByDriverService() {
      return (
        this.finishedTime &&
        this.delivery.status === ServiceStatus.NUM_DRIVER_CANCELLED
      );
    },

    scheduledAt() {
      if (this.isScheduledService) {
        return fromUnixTime(this.additionalOptions.scheduled);
      }
      return "";
    },

    scheduledServiceDate() {
      if (this.isScheduledService) {
        const isSameDay = isToday(this.scheduledAt);
        const isNextDay = isTomorrow(this.scheduledAt);
        const scheduledDay = format(this.scheduledAt, "d 'de' MMM. 'de' yyyy");
        let deliveryDate = "";
        if (isSameDay) {
          deliveryDate = "Hoy";
        } else if (isNextDay) {
          deliveryDate = "Mañana";
        } else {
          deliveryDate = `el día ${scheduledDay}`;
        }
        return deliveryDate;
      }
      return "";
    },

    scheduledServiceTime() {
      if (this.isScheduledService) {
        return format(this.scheduledAt, "h:mm a");
      }
      return "";
    },

    deliveryId() {
      return this.$route.params.id;
    },

    isParcel() {
      return ServiceType.isParcel(this.type);
    },

    calculatedLogs() {
      const logs = [];
      if (this.creationTime) {
        logs.push({
          eventName: "Entrega creada con éxito",
          date: this.creationTime,
          createdAt: this.delivery.createdAt
        });
      }

      if (this.arrivedAt) {
        logs.push({
          eventName: `El mensajero llegó a ${this.delivery.origin.name}`,
          date: this.creationTime,
          createdAt: this.delivery.createdAt
        });
      }

      if (this.deliveredTime) {
        logs.push({
          eventName: `El mensajero llegó a ${this.delivery.destination.name}`,
          date: this.deliveredTime,
          createdAt: this.deliveredAt
        });
      }

      if (this.exitTime) {
        logs.push({
          eventName: `El mensajero salió a su destino`,
          date: this.exitTime,
          createdAt: this.exitAt
        });
      }

      if (this.delivery.destination && this.delivery.destination.signed) {
        logs.push({
          eventName: `Firma realizada por ${this.delivery.destination.name} ${this.delivery.destination.signed}`,
          date: this.deliveredTime,
          createdAt: this.deliveredAt
        });
      }

      if (this.isSuccessfulService) {
        logs.push({
          eventName: `Paquete entregado exitosamente finalizado`,
          date: this.finishedTime,
          createdAt: this.finishedAt
        });
      }

      return logs;
    },

    parsedLogs() {
      if (this.logs?.length) {
        return this.logs.map(log => {
          return {
            id: log.id,
            createdAt: parseISO(log.createdAt),
            date: formatISO(log.createdAt, "d 'de' MMM,  u  h:mm a"),
            eventName: Logs.get[log.event]
          };
        });
      }
      return [];
    },

    parsedIncidences() {
      if (this.incidences.length > 0) {
        const incidences = [];
        this.incidences.forEach(inc => {
          const eventName = incidenceMessage(
            inc.categoryId,
            inc.incidenceId,
            false
          );
          if (eventName) {
            incidences.push({
              id: inc.id,
              createdAt: parseISO(inc.createdAt),
              date: formatISO(inc.createdAt, "d 'de' MMM,  u  h:mm a"),
              eventName
            });
          }
        });
        return incidences;
      }
      return [];
    },

    sortedLogs() {
      return [
        ...this.calculatedLogs,
        ...this.parsedLogs,
        ...this.parsedIncidences
      ].sort((a, b) => a.createdAt - b.createdAt);
    }
  },

  created() {
    this.getLogs();
  },

  methods: {
    getLogs() {
      this.loading = true;
      Deliveries.logs(this.deliveryId)
        .then(res => {
          const data = res.data[0];
          this.arrivedAt = data.arrived;
          this.deliveredAt = data.delivered;
          this.logs = data?.logs;
          this.incidences = data?.incidences;
          this.exitAt = data.exit;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.z-delivery-normal-logs {
  font-size: 12px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
  }

  .scheduled-delivery-register {
    padding: 0.75rem 0rem;
    display: flex;
    justify-content: space-between;

    .cancel-scheduled {
      color: $alizarin;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .z-delivery-normal-logs {
    font-size: 12px;

    .scheduled-delivery-register {
      padding: 0.75rem 0rem;
    }
  }
}
</style>
