<template>
  <div class="py-4 text-sm">
    <div
      v-if="additionals.restrictedItems"
      class="border-bottom d-flex justify-content-between"
    >
      <p>Contiene artículos restringidos (+18)</p>
    </div>
    <div
      v-if="additionals.signature"
      class="border-bottom d-flex justify-content-between mt-3"
    >
      <p>Requiere firma en el destino</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryDetailAdditionals",

  props: {
    additionals: {
      type: Object,
      default: null
    }
  }
};
</script>
