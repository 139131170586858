<template>
  <z-badge class="delivery-mode-badge">
    {{ name }}
  </z-badge>
</template>

<script>
import DeliveryMode from "../constants/parcels/deliveryMode";
import ZBadge from "./ZBadge";

export default {
  name: "ZBadgeDeliveryMode",

  components: {
    ZBadge
  },

  props: {
    type: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      defaultColor: "red",
      defaultContent: "S"
    };
  },

  computed: {
    name() {
      return DeliveryMode.get[this.type];
    },
    displayContent() {
      if (this.content != null) return this.content;
      else if (this.type != null) return this.contentTypeMatch[this.type];
      return this.defaultContent;
    },
    backgroundColor() {
      if (this.color != null) return this.color;
      if (this.type != null) return this.colorTypeMatch[this.type];
      return this.defaultColor;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.delivery-mode-badge {
  background-color: $solitude;
  border-radius: 6px;
  color: $smoky;
  font-size: 0.85rem;
  font-weight: 500 !important;
  padding: 8px 6px !important;
}
</style>
