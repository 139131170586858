<template>
  <div class="py-4 text-sm">
    <div v-if="deliveryProof.receiverType" class="border-bottom detail-row ">
      <p>Tipo de titular</p>
      <p>{{ deliveryProof.receiverType }}</p>
    </div>
    <div v-if="deliveryProof.receiverName" class="border-bottom detail-row ">
      <p>Nombre del titular</p>
      <p>{{ deliveryProof.receiverName }}</p>
    </div>
    <div v-if="deliveryProof.signature" class="border-bottom detail-row ">
      <p>Firma</p>
      <div>
        <img
          class="signature"
          :src="`data:image/png;base64,${deliveryProof.signature}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryDetailProof",

  props: {
    deliveryProof: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;

  p {
    margin: 0;
  }
}
</style>
