<template>
  <div>
    <div class="d-flex justify-content-between align-items-start">
      <div>
        <p class="font-weight-bold text-2xl mb-0 mt-0">
          En camino a {{ destinationName }}
        </p>
        <p v-if="delivery.deliveryDate" class="text-lg mb-1">
          {{ deliveryDate }}
        </p>
        <status-parcel v-if="isParcel" :status="delivery.status" />
        <status-service v-else :status="delivery.status" class="mt-3" />
        <z-badge-delivery-mode
          v-if="showDeliveryMode"
          :type="delivery.deliveryMode"
          class="ml-3"
        />
      </div>

      <div class="d-flex">
        <z-button variant="secondary" fit="auto" class="mr-2" @click="print">
          <font-awesome-icon icon="print" size="lg" />
        </z-button>
        <z-tracking-link
          :id="delivery.id"
          :is-parcel="isParcel"
          variant="button"
          button-text="Compartir viaje"
          tooltip-placement="bottom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ZBadgeDeliveryMode from "@zubut/common/src/components/ZBadgeDeliveryMode";
import ZTrackingLink from "@zubut/common/src/components/ZTrackingLink";
import StatusParcel from "@zubut/common/src/components/StatusParcel";
import { formatISO } from "@zubut/common/src/utils/time";
import StatusService from "@zubut/common/src/components/StatusService";

export default {
  name: "DeliveryDetailHeader",

  components: {
    StatusService,
    ZBadgeDeliveryMode,
    ZTrackingLink,
    StatusParcel
  },

  props: {
    delivery: {
      type: Object,
      default: () => {
        return {
          client: { name: "" },
          reservationBlock: { start: "", end: "" },
          status: null
        };
      }
    },
    isParcel: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    destinationName() {
      return this.delivery.destination.name;
    },
    showDeliveryMode() {
      return this.delivery.deliveryMode != null;
    },
    deliveryDate() {
      return formatISO(
        this.delivery.deliveryDate,
        "'La fecha estimada de entrega es el' d 'de' MMM. 'a las' H:mm"
      );
    }
  },

  methods: {
    print() {
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }
};
</script>
