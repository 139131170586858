import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Deliveries");

let Deliveries = Object.assign(
  {},
  {
    relation: "",

    getDetails(id) {
      return loopback.get(`/Deliveries2/${id}/details`);
    }
  },
  modelObject
);

export default Deliveries;
